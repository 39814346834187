// Import Fuse core library
@import '@fuse/scss/core';

// Import app.theme.scss
@import 'app/app.theme';

@import '../node_modules/ngx-toastr/toastr.css';
@import '../node_modules/ngx-spinner/animations/ball-spin-clockwise.css';
@import '../node_modules/ngx-spinner/animations/ball-scale-multiple.css';
@import '../node_modules/ngx-spinner/animations/ball-atom.css';
@import '../node_modules/video.js/dist/video-js.css';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

.ql-tooltip{
  left:0 !important;
}
.mat-dialog-container {
  padding: 0px !important;
}
.col-12{
  margin: 0 -15px;
}
.txt-color-blue {
  color: #57889c !important;
}
.txt-color-themeBlue {
  color: #00c6d8 !important;
}
.txt-color-blueLight {
  color: #92a2a8 !important;
}
.txt-color-blueDark {
  color: #4c4f53 !important;
}
.txt-color-green {
  color: #356e35 !important;
}
.txt-color-greenLight {
  color: #71843f !important;
}
.txt-color-greenDark {
  color: #496949 !important;
}
.txt-color-red {
  color: #ff8845 !important;
}
.txt-color-yellow {
  color: #b09b5b !important;
}
.txt-color-orange {
  color: #b19a6b !important;
}
.txt-color-orangeDark {
  color: #a57225 !important;
}
.txt-color-pink {
  color: #ac5287 !important;
}
.txt-color-pinkDark {
  color: #a8829f !important;
}
.txt-color-purple {
  color: #6e587a !important;
}
.txt-color-darken {
  color: #404040 !important;
}
.txt-color-lighten {
  color: #d5e7ec !important;
}
.txt-color-white {
  color: #fff !important;
}
.txt-color-grayDark {
  color: #525252 !important;
}
.txt-color-magenta {
  color: #6e3671 !important;
}
.txt-color-teal {
  color: #568a89 !important;
}
.txt-color-redLight {
  color: #a65858 !important;
}
.txt-color-grayLight {
  color: #9aaec1 !important;
}
.bg-color-themeBlue {
  background-color: #00c6d8 !important;
}
.bg-color-blue {
  background-color: #57889c !important;
}
.bg-color-blueLight {
  background-color: #92a2a8 !important;
}
.bg-color-blueDark {
  background-color: #4c4f53 !important;
}
.bg-color-green {
  background-color: #356e35 !important;
}

.bg-color-orange {
  background-color: #356e35 !important;
}
.bg-color-greenLight {
  background-color: #00d6c1 !important;
}
.bg-color-greenDark {
  background-color: #496949 !important;
}
.bg-color-red {
  background-color: #ffd04e !important;
}
.bg-color-yellow {
  background-color: #b09b5b !important;
}
.bg-color-orange {
  background-color: #ff8845 !important;
}
.bg-color-orangeDark {
  background-color: #a57225 !important;
}
.bg-color-pink {
  background-color: #ac5287 !important;
}
.bg-color-pinkDark {
  background-color: #a8829f !important;
}
.bg-color-purple {
  background-color: #6e587a !important;
}
.bg-color-darken {
  background-color: #404040 !important;
}
.bg-color-lighten {
  background-color: #d5e7ec !important;
}
.bg-color-white {
  background-color: #fff !important;
}
.bg-color-whiteSmoke {
  background-color: whitesmoke !important;
}
.bg-color-grayDark {
  background-color: #525252 !important;
}
.bg-color-magenta {
  background-color: #6e3671 !important;
}
.bg-color-teal {
  background-color: #568a89 !important;
}
.bg-color-redLight {
  background-color: #a65858 !important;
}
.mat-spinner circle {
  stroke: #fff !important;
}
.external-web-spinner .mat-spinner circle {
  stroke: #00afbf !important;
}
.external-web-spinner button .mat-spinner {
  margin-left: 5px;
  circle {
    stroke: #FFFFFF !important;
  }
}
.external-toggle span.mat-slide-toggle-bar {
  transform: rotate(180deg)!important;
}
.table-search {
  .top-bg {
    @include media-breakpoint('lt-md') {
      height: 256px;
    }
  }

  > .center {
    .search-wrapper {
      width: 100%;
      border-radius: 28px;
      overflow: hidden;
      @include mat-elevation(1);

      @include media-breakpoint('xs') {
        width: 100%;
      }

      .search {
        width: 100%;
        height: 48px;
        line-height: 48px;
        padding: 0 18px;

        input {
          height: 48px;
          min-height: 48px;
          max-height: 48px;
          padding: 0 16px;
          border: none;
          outline: none;
        }
      }
    }
  }
}
.online-icon {
  display: inline-block;
  min-width: 10px;
  padding: 3px 6px;
  font-size: 8px;
  font-weight: 700;
  color: #fff;
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  border-radius: 50%;
  margin-right: 5px;
}
.online-icon-color {
  background-color: #00c6d8;
}
// for common search
.search-wrapper {
  width: 100%;
  max-width: 480px;
  border-radius: 28px;
  overflow: hidden;

  .search {
    width: 100%;
    height: 48px;
    line-height: 48px;
    padding: 0 18px;

    input {
      width: 100%;
      height: 48px;
      min-height: 48px;
      max-height: 48px;
      padding: 0 16px;
      border: none;
      outline: none;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.primary_dot {
  height: 15px;
  width: 15px;
  // background-color: #3c4252;
  border-radius: 50%;
  display: inline-block;
}
@media only screen and (min-width: 768px) {
  .home-layout {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0;
    //background: #f2f2f2; /* fallback for old browsers */

    // background: -webkit-linear-gradient(to right, #2d323e, hsl(222, 16%, 21%));
    // background: linear-gradient(to right, #2d323e, #2d323e)
    /*background: -webkit-linear-gradient(to right, #acb6e5, #74ebd5);  /* Chrome 10-25, Safari 5.1-6 */
    /* background: linear-gradient(
      to right,
      #acb6e5,
      #74ebd5
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
  .upcoming-events {
    flex: 28%;
    padding: 0 4px;
  }

  @media (max-width: 800px) {
    .upcoming-events,
    .home-layout-content {
      flex: 100%;
    }
  }
  .findClass {
    background: #f2f2f2;
  }
  .eventFoundHome,
  .noEventFound {
    background: white;
  }
  .bw-widget_date {
    background-color: #f2f2f2 !important;
    border-color: transparent !important;
  }
  .nav.horizontal {
    background: white;
    color: black;
  }
  /*body.theme-default .accent {
   background-color: transparent !important;
  color: #00C6D8 !important;
}*/
  // body.theme-default .mat-datepicker-toggle,
  // body.theme-default .mat-datepicker-content .mat-calendar-next-button,
  // body.theme-default .mat-datepicker-content .mat-calendar-previous-button {
  //   color: rgb(255 255 255);
  // }
}


.pdf-color {
  color: #ff1a00 !important;
}

.button-hover {
  &:hover {
    button {
      border: none;
      cursor: pointer;
      @include mat-elevation(12);
    }
  }
}
.bg-color-search {
  background: white;
  color: gray;
}
a.clipBoard_ {
  height: 20px;
  color: white;
}

.underline {
  text-decoration: underline;
}
.publicSchedule {
  background: white;
}

.timepicker-overlay {
  z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
  z-index: 10000000 !important;
}

.btn-primary-hollow-md:hover {
  background-color: #2c699d;
  color: #fff;
}
.btn-primary-hollow-md {
  color: #2c699d;
  line-height: 30px;
  font-weight: 600;
  border: 0 none;
  background-color: #ffffff;
  border: 1px solid #2c699d;
  padding: 0 10px 0 10px;
  font-size: 13px;
}
.btn-primary-hollow-md:focus {
  background-color: #ffffff;
  color: #2c699d;
}

.btn-primary-hollow-big:hover {
  background-color: #2c699d;
  color: #fff;
}
.btn-primary-hollow-big {
  color: #2c699d;
  height: 54px;
  line-height: 40px;
  font-weight: 600;
  border: 0 none;
  background-color: #ffffff;
  border: 1px solid #2c699d;
  padding: 0 10px 0 10px;
  font-family: 'Hauora Sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 4px;
}
.btn-primary-hollow-big:focus {
  background-color: #ffffff;
  color: #2c699d;
}

.hide-mobile {
  @include media-breakpoint('xs') {
    display: none !important;
  }
}
.show-mobile {
  @include media-breakpoint('md') {
    display: none !important;
  }
  @include media-breakpoint('lg') {
    display: none !important;
  }
  @include media-breakpoint('xl') {
    display: none !important;
  }
}
.mat-checkbox-disabled .mat-checkbox-background {
  background-color: #00c6d8 !important;
}

.upcoming-header {
  border-color: #00afbf;
  background: #ffffff;
  color: #000000;
}
.program-card-header {
  background: #2196f3;
}
.a_class {
  color: #00c6d8;
  cursor: pointer;
}
.a_class:hover {
  color: #00afbf;
}
.bg-theme {
  background-color: #7fbbf3;
}

.new-header {
  height: 70px;
  //margin: 0 15px;
  padding: 16px;
  background: #f8f8ff;
}

@media screen and (max-width: 600px) {
  .new-header {
    min-height: 70px;
    height: auto;
  }
}

.batchicon_edit {
  color: #3e3737;
}

.batchicon_delete {
  color: #ff8845;
}
.deleteBtn {
  font-weight: normal !important;
}
.deleteBtn:hover {
  font-weight: bold !important;
}

.titleClass {
  text-transform: uppercase;
  color: rgb(139, 142, 149);
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 15px;
}

.subTitleClass {
  color: rgb(139, 142, 149);
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 15px;
}

.boxshadow-media {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 4px 0px;
  padding: 20px;
  margin: auto auto 0px;
  position: relative;
  border-radius: 4px;
  margin-bottom: 20px;
}

.mediaBtn {
  color: rgba(0, 0, 0, 0.24) 0px 1px 4px 0px !important;
}
.mediaBtn:hover {
  color: green !important;
}

.settingTitle {
  text-align: left;
  font-size: 18px;
  color: #666a71;
  margin: 0;
  margin-bottom: 10px;
  font-weight: 500;
}

.titleBtn {
  cursor: pointer;
  color: #000000de !important;
}
.titleBtn:hover {
  color: #443b3bde !important;
}

.courseTitleClass {
  /*text-transform: uppercase;*/
  color: #666a71;
  font-size: 16px;
  font-weight: 500;
}

.courseSubTitleClass {
  /*text-transform: uppercase;*/
  color: rgb(139, 142, 149);
  font-size: 14px;
  font-weight: 500;
}

.pageHead {
  font-size: 24px;
  background-color: #ffffff;
  color: #000000;
  font-weight: 600;
}

.newcolor {
  color: rgb(139, 142, 149);
}

//Hide mat-toggle-button from tabular/list view

mat-button-toggle-group.ml-16 mat-button-toggle:nth-child(2) {
  display: none;
}

mat-button-toggle.mat-button-toggle-checked {
  background: #e0e0e0 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

body.theme-default .mat-slide-toggle-bar {
  width: 28px !important;
}
.mat-slide-toggle-thumb {
  width: 12px !important;
  height: 12px !important;
}
//.mat-slide-toggle-thumb-container{
//  left: -8px !important;
//}
.mat-slide-toggle-thumb-container {
  left: -3px !important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar .mat-slide-toggle-thumb-container {
  left: -8px !important;
}
.external-link-toggle mat-slide-toggle .mat-slide-toggle-bar .mat-slide-toggle-thumb-container {
  transform: translate(16px, 4px) !important;
}
.external-link-toggle mat-slide-toggle.mat-checked .mat-slide-toggle-bar .mat-slide-toggle-thumb-container {
  transform: translate(11px, 4px) !important;
}

body.theme-default .external-link-toggle .mat-slide-toggle-bar {
  width: 28px !important;
}
.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background: #00afbf !important;
}
body.theme-default .mat-icon.mat-accent {
  color: #000000;
}
body.theme-default .mat-mini-fab.mat-accent {
  background-color: #ffffff;
  color: #00afbf;
}
// .mat-chip-list-wrapper .mat-standard-chip{
//   background: #ffffff !important;
//   //border: 1px solid #000000;
//   color: #000000 !important;
// }
.mat-tab-body-wrapper header.accent {
  background-color: #ffffff !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  .title {
    color: #000000 !important;
  }
}
.mat-tab-body-wrapper .h1.accent {
  background-color: #ffffff !important;
  .title_new {
    color: #000000 !important;
  }
}
//body.theme-default .accent{
//  background-color: #FFFFFF !important;
//  color: #000000 !important;
//}
body.theme-default .mat-toolbar.mat-accent {
  background-color: #ffffff !important;
  color: #000000 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  .title {
    color: #000000 !important;
  }
}
body.theme-default .mat-icon-button {
  color: #000000;
}
.upcoming_header {
  align-items: baseline !important;
}
body.theme-default .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ffffff;
}
.campaignEmail .mat-slide-toggle-thumb {
  transform: translate(6px, 4.5px);
}
.campaignEmail .mat-slide-toggle-bar {
  height: 15px !important;
}
// button.mat-focus-indicator {
//   //background: red;
// }
.logo-text {
  color: #00c6d8;
}
.logo-icon {
  color: #00c6d8;
}
mat-form-field .mat-form-field-flex {
  max-height: 84px;
}
.external-url {
  width: auto !important;
}
span[role='progressbar'] {
  background: #f5f5f5 !important;
}
//Hover button
button.hoverEffect:hover {
  background: #f8f8ff !important;
  transition: transform 0.5s !important;
  transform: scale(1.2);
}
.mat-error {
  color: #ff8845 !important;
  margin-bottom: 2px;
}
.mat-form-field-invalid {
  color: #ff8845 !important;
  caret-color: #ff8845 !important;
}
footer mat-toolbar.mat-toolbar {
  height: 30px !important;
}
.nav-link.ng-star-inserted.active.accent {
  background: #ff8845 !important;
}
.page-header .external-toggle span.mat-slide-toggle-thumb-container {
  transform: translate(16px, 4px) !important;
}
.page-header .external-toggle.mat-checked span.mat-slide-toggle-thumb-container {
  transform: translate(11px, 4px) !important;
}
mat-form-field.pt-8 .mat-form-field-outline {
  background: #fff;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 48px;
}
mat-form-field input{
  line-height: 24px!important;
}

.dialog-content-wrapper .mat-slide-toggle.mat-checked .mat-slide-toggle-bar .mat-slide-toggle-thumb-container {
  left: -2px !important;
}

.zero-ul ul{
  margin:0px;
}

.progress-snackbar {
  background-color: white !important;
  color: black !important;
}

.custom-chip {
  border-radius: 5px;
  color: white;
  margin-left: 10px;
  padding: 2px 4px;

  &.red{
    background-color: #ff1a00 !important;
    color:white !important;
  }
  
  &.orange{
    background-color: #ff8845 !important;
    color:white !important;
  }

  &.green{
    background-color:#3ef584 !important;
    color:black !important;
  }

  &.yellow{
    background-color:#ebf13c !important;
    color:black !important;
  }

  &.blue{
    background-color:#3bdcf6 !important;
    color:white !important;
    cursor: pointer !important;
  }
}
