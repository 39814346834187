@mixin fuse-search-bar-theme($theme) {

    $background: map-get($theme, background);

    .fuse-search-bar {

        &.expanded {
            background-color: map-get($background, background);
            box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
            border-radius: 50px;
            background: #FFFFFF;
        }
    }
}
